import React from 'react'
import { connect } from 'react-redux'
// import { navigate } from 'gatsby'
// import * as EmailValidator from 'email-validator';
// import { SEO, Header } from '../components'
// import website from '../../config/website'
// import {
//   CartWrapper,
//   Title,
//   CartDeliveryForm,
//   Order,
//   FormBlocskWrapper
// } from '../components/Style/cartStyle';
// import ShopBreadcrumb from '../components/ShopBreadcrumb'
// import PhoneInput from 'react-phone-input-2'
// import countries from '../../config/countries'
// import 'react-phone-input-2/lib/style.css'

class Delivery extends React.Component {

  // state = {
  //   catalog: false
  // }

  // constructor() {
  //   super();

  //   let loaded = false
  //   if (typeof localStorage !== 'undefined') {
  //     const orderPath = JSON.parse(localStorage.getItem('dischr-order-path'));
  //     if (!orderPath) {
  //       navigate('/shop/');
  //     } else {
  //       if (orderPath.indexOf('delivery') < 0) {
  //         localStorage.setItem('dischr-order-path', JSON.stringify(['cart', 'delivery']));
  //       }
  //       loaded = true;
  //     }
  //   }
    
  //   this.state = {
  //     name: null,
  //     firstname: null,
  //     email: null,
  //     // phone: '',
  //     address: null,
  //     city: null,
  //     zipcode: null,
  //     country: '1',
  //     errors: [],
  //     _loaded: loaded
  //   };
  // }

  // componentDidMount() {
  //   const search = this.props.location.search.split('=');
  //   const isCatalog = (search[0].replace('?', '') === 'catalog' && search[1] === '1');
  //   this.setState({ catalog: isCatalog })

  //   if (typeof localStorage !== 'undefined') {
  //     const infos = JSON.parse(localStorage.getItem('dischr-cart-delivery'));
  //     if (infos) {
  //       this.setState({ ...infos });
  //     }
  //   }
  // }

  // submitForm = () => {
  //   let errors = [];

  //   if (!this.state.name) {
  //     errors.push('name');
  //   }

  //   if (!this.state.firstname) {
  //     errors.push('firstname');
  //   }

  //   if (!this.state.email || !EmailValidator.validate(this.state.email)) {
  //     errors.push('email');
  //   }

  //   if (!this.state.address) {
  //     errors.push('address');
  //   }

  //   if (!this.state.city) {
  //     errors.push('city');
  //   }

  //   if (!this.state.zipcode) {
  //     errors.push('zipcode');
  //   }

  //   if (this.state.country === '1') {
  //     errors.push('country');
  //   }

  //   if (errors.length === 0) {
  //     if (typeof localStorage !== 'undefined') {
  //       localStorage.setItem('dischr-cart-delivery', JSON.stringify(this.state));
  //       {
  //         this.state.catalog ? navigate(`/payment/?catalog=1`) : navigate(`/payment/`);
  //       }
  //     }
  //   } else {
  //     this.setState({ errors: errors })
  //   }
  // }

  render() {
    // const {
    //   location,
    //   pageContext: { locale }
    // } = this.props;
    // const context = { slug: 'delivery', parent: 'shop' };
    
    // return (
    //   <React.Fragment>
    //     <SEO title={`Disch Remy - Order delivery`} pathname={location.pathname} locale={locale} />
    //     <Header {...context} />
    //     {
    //       this.state._loaded && (
    //         <React.Fragment>
    //           <ShopBreadcrumb {...context} catalog={this.state.catalog} />
    //           <CartWrapper id={website.skipNavId}>
    //             <React.Fragment>
    //               <Title><h2>Delivery informations</h2></Title>
    //               <CartDeliveryForm>
    //                 <FormBlocskWrapper>
    //                   <div>
    //                     <div>
    //                       <label>Firstname*</label>
    //                       <input 
    //                         style={{border: `1px solid ${this.state.errors.indexOf('firstname') >= 0 ? '#ff0000' : '#ddd'}`}} 
    //                         type="text" 
    //                         name="firstname" 
    //                         id="firstname" 
    //                         placeholder="Type your firstname" 
    //                         defaultValue={this.state.firstname}
    //                         onChange={(e) => this.setState({ firstname: e.target.value, errors: this.state.errors.filter(i => i !== 'firstname') })} />
    //                     </div>
    //                     <div>
    //                       <label>Name*</label>
    //                       <input 
    //                         style={{border: `1px solid ${this.state.errors.indexOf('name') >= 0 ? '#ff0000' : '#ddd'}`}} 
    //                         type="text" 
    //                         name="name" 
    //                         id="name" 
    //                         placeholder="Type your name" 
    //                         defaultValue={this.state.name}
    //                         onChange={(e) => this.setState({ name: e.target.value, errors: this.state.errors.filter(i => i !== 'name') })} />
    //                     </div>
    //                     <div>
    //                       <label>
    //                         Email address*
    //                         {
    //                           this.state.errors.indexOf('email') >= 0 && (
    //                             <small>Your email address seems to be invalid, please type a valid email address</small>
    //                           )
    //                         }
    //                       </label>
    //                       <input 
    //                         style={{border: `1px solid ${this.state.errors.indexOf('email') >= 0 ? '#ff0000' : '#ddd'}`}} 
    //                         type="email" 
    //                         name="email" 
    //                         id="email" 
    //                         placeholder="Type your email address" 
    //                         defaultValue={this.state.email}
    //                         onChange={(e) => this.setState({ email: e.target.value, errors: this.state.errors.filter(i => i !== 'email') })} />
    //                     </div>
    //                     {/* <div>
    //                       <label>Phone*</label>
    //                       <PhoneInput
    //                         country={'fr'}
    //                         onlyCountries={['fr','be','ch','lu']}
    //                         value={this.state.phone}
    //                         onChange={phone => this.setState({ phone })}
    //                       />
    //                     </div> */}
    //                   </div>
    //                   <div>
    //                     <div>
    //                       <label>Address*</label>
    //                       <input 
    //                         style={{border: `1px solid ${this.state.errors.indexOf('address') >= 0 ? '#ff0000' : '#ddd'}`}} 
    //                         type="text" 
    //                         name="address" 
    //                         id="address" 
    //                         placeholder="Type your postal address" 
    //                         defaultValue={this.state.address}
    //                         onChange={(e) => this.setState({ address: e.target.value, errors: this.state.errors.filter(i => i !== 'address') })} />
    //                     </div>
    //                     <div className="subWrapper">
    //                       <div>
    //                         <label>City*</label>
    //                         <input
    //                           style={{ border: `1px solid ${this.state.errors.indexOf('city') >= 0 ? '#ff0000' : '#ddd'}` }}
    //                           type="text"
    //                           name="city"
    //                           id="city"
    //                           placeholder="ex: Paris"
    //                           defaultValue={this.state.city}
    //                           onChange={(e) => this.setState({ city: e.target.value, errors: this.state.errors.filter(i => i !== 'city') })} />
    //                       </div>
    //                       <div>
    //                         <label>Postal code*</label>
    //                         <input 
    //                           style={{border: `1px solid ${this.state.errors.indexOf('zipcode') >= 0 ? '#ff0000' : '#ddd'}`}} 
    //                           type="text" 
    //                           name="zipcode" 
    //                           id="zipcode" 
    //                           placeholder="ex: 75017" 
    //                           defaultValue={this.state.zipcode}
    //                           onChange={(e) => this.setState({ zipcode: e.target.value, errors: this.state.errors.filter(i => i !== 'zipcode') })} />
    //                       </div>
    //                     </div>
    //                     <div>
    //                       <label>Country*</label>
    //                       <select 
    //                         style={{ border: `1px solid ${this.state.errors.indexOf('country') >= 0 ? '#ff0000' : '#ddd'}` }}
    //                         name="country" 
    //                         id="country" 
    //                         value={this.state.country}
    //                         onChange={(e) => { this.setState({ country: e.target.value, errors: this.state.errors.filter(i => i !== 'country') }) }}
    //                       >
    //                         <option value="1" disabled>Choose your country</option>
    //                         {
    //                           countries.map((i) => {
    //                             return (
    //                               <option key={i.code} value={i.code}>{i.name}</option>
    //                             )
    //                           })
    //                         }
    //                       </select>
    //                     </div>
    //                   </div>
    //                 </FormBlocskWrapper>
    //                 <Order>
    //                   {
    //                     this.state.catalog ? (
    //                       <React.Fragment>
    //                         <button onClick={(e) => { e.preventDefault(); navigate(`/cart/?catalog=1`); }}>Back to cart</button>
    //                       </React.Fragment>
    //                     ) : (
    //                       <React.Fragment>
    //                         <button onClick={(e) => { e.preventDefault(); navigate(`/cart/`); }}>Back to cart</button>
    //                       </React.Fragment>
    //                     )
    //                   }
    //                   <button className="orange" onClick={(e) => { e.preventDefault(); this.submitForm(); }}>Submit</button>
    //                 </Order>
    //               </CartDeliveryForm>
    //             </React.Fragment>
    //           </CartWrapper>
    //         </React.Fragment>
    //       )
    //     }
    //   </React.Fragment>
    // )
    return <div></div>
  }
}

const mapStateToProps = (state) => ({
  orderedItems: state.order
});
export default connect(mapStateToProps)(Delivery);